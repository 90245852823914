import { Axis } from "@/utils/consts/axis";
import { CourseFile } from "@/utils/consts/course-file";
import { Difficulty } from "@/utils/consts/difficulty";
import { Is } from "@/utils/consts/is";
import { LotSize } from "@/utils/consts/lot-size";

export const useConsts = () => {
  // 是否是 Learn 平台
  const IsLearnPlatform = true;

  // 是否是 Admin 平台
  const IsAdminPlatform = false;

  // 基础地址
  const BaseUrl = process.env["BASE_URL"];

  // 接口地址
  const ApiUrl = process.env["VUE_APP_API_URL"];

  // 静态文件地址
  const StaticUrl = process.env["VUE_APP_STATIC_URL"];

  // 分页大小
  const PageSize = 10;

  // 基础地址
  const SiteUrl = process.env["VUE_APP_SITE_URL"];

  // CDN 地址
  const CdnUrl = process.env["VUE_APP_CDN_URL"];

  const WsUrl = process.env["VUE_APP_WS_URL"];

  // Scratch 地址
  const ScratchUrl = process.env["VUE_APP_SCRATCH_URL"];

  // FVBOT 地址
  const FvbotUrl = process.env["VUE_APP_FVBOT_URL"];

  // 代理网站基础地址
  const ProxySiteUrl = process.env["VUE_APP_PROXY_SITE_URL"];

  // Office 预览服务地址
  const OfficeServiceUrl = process.env["VUE_APP_OFFICE_SERVICE_URL"];

  // 直播室地址
  const LiveUrl = process.env["VUE_APP_LIVE_URL"];

  // 百家云直播室地址
  const BjyLiveUrl = process.env["VUE_APP_BJY_LIVE_URL"];

  // 星期
  const Week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];

  return {
    Axis,
    CourseFile,
    Difficulty,
    Is,
    LotSize,
    IsLearnPlatform,
    IsAdminPlatform,
    BaseUrl,
    ApiUrl,
    StaticUrl,
    PageSize,
    SiteUrl,
    CdnUrl,
    WsUrl,
    ScratchUrl,
    FvbotUrl,
    ProxySiteUrl,
    OfficeServiceUrl,
    LiveUrl,
    BjyLiveUrl,
    Week,
  };
};
