import { reactive } from "vue";

export default {
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const cDrawer = reactive({
      visible: false,
    });

    return {
      cDrawer,
    };
  },
};
