<template>
  <el-dialog
    title="试题"
    v-model="cDialog.visible"
    width="900px"
    destroy-on-close
    @closed="$emit('cancel')"
  >
    <div
      class="c-scrollbar"
      style="
        max-height: 620px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 10px;
      "
    >
      <c-exam-question-list ref="examQuestionList" />
    </div>
    <template #footer>
      <el-button @click="cDialog.visible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script src="./script.js"></script>
