<template>
  <div
    v-if="item.type === 'RichText'"
    class="is-rich-text"
    v-html="$helpers.formatHtml(item.value)"
  ></div>
  <div v-else-if="item.type === 'Exam'">
    <c-question
      :questions-list="{ items: item.value }"
      :results-detail="examResultsDetail"
      @change="onQuestionChange"
    />
  </div>
  <div v-else-if="item.type === 'Courseware'" class="is-courseware">
    <template v-if="showArea === 'sidebar'">
      <p :data-src="item.value.id">
        <i class="c-iconfont c-iconfont--ppt"></i>
        {{ item.value.name }}
      </p>
    </template>
    <template v-else>
      <iframe
        class="is-office"
        :src="getOfficeUrl(item.value)"
        frameborder="0"
      ></iframe>
    </template>
  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
