import { onMounted, reactive, ref } from "vue";
import { useValidators } from "vue-validation";
import { v4 as uuidv4 } from "uuid";
import { ElMessage } from "element-plus";
import { useAuth } from "@/composables/use-auth";
import { publicAccountsApi } from "@/apis/public/accounts";

export default {
  emits: ["ok", "close-dialog"],
  setup(props, context) {
    const { isRequired } = useValidators();

    const { login } = useAuth();

    const formRef = ref(null);

    const cForm = reactive({
      model: {
        clientId: uuidv4(),
      },
      rules: {
        account: [isRequired({ label: "用户名" })],
        password: [isRequired({ label: "密码" })],
        captcha: [isRequired({ label: "验证码" })],
      },
    });

    const cCaptcha = reactive({
      data: "",
    });

    onMounted(async () => {
      await renderCaptcha();
    });

    const renderCaptcha = async () => {
      cCaptcha.data = await publicAccountsApi.post({
        action: "getSvgCaptcha",
        body: {
          clientId: cForm.model.clientId,
        },
      });
    };

    const submit = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          try {
            const user = await login(cForm.model);

            ElMessage.success("登录成功");

            context.emit("ok", { user });
            context.emit("close-dialog");
          } catch (e) {
            await renderCaptcha();
          }
        }
      });
    };

    return {
      formRef,
      cForm,
      cCaptcha,
      renderCaptcha,
      submit,
    };
  },
};
