<template>
  <template v-if="cForm.model.questions.length">
    <div
      v-for="(question, questionIndex) in questionsList.items"
      :key="question.id"
      class="c-exam"
    >
      <div class="c-block__body">
        <div class="cc-question">
          <div class="cc-question__index">{{ questionIndex + 1 }}.</div>
          <span v-html="$helpers.formatHtml(question.question)"></span>
        </div>
        <ul
          v-if="['SingleSelect', 'MultipleSelect'].includes(question.type)"
          class="cc-options"
        >
          <li
            v-for="(option, optionIndex) in ['A', 'B', 'C', 'D']"
            :key="optionIndex"
            class="cc-options__item"
          >
            <div class="cc-options__check">
              <el-checkbox
                :model-value="
                  cForm.model.questions[questionIndex].options[optionIndex]
                "
                :disabled="isDisabled"
                @change="onOptionChange(questionIndex, optionIndex)"
              />
            </div>
            <div class="cc-options__option">{{ option }}.</div>
            <span
              v-html="$helpers.formatHtml(question.options[optionIndex])"
            ></span>
          </li>
        </ul>
        <ul v-else-if="question.type === 'TrueOrFalse'" class="cc-options">
          <li
            v-for="(option, optionIndex) in enums.ExamQuestionTrueOrFalse"
            :key="optionIndex"
            class="cc-options__item"
          >
            <div class="cc-options__check">
              <el-checkbox
                :model-value="
                  cForm.model.questions[questionIndex].options[optionIndex]
                "
                :disabled="isDisabled"
                @change="onOptionChange(questionIndex, optionIndex)"
              />
            </div>
            <div class="cc-options__option">{{ option.value }}.</div>
            <p>{{ option.label }}</p>
          </li>
        </ul>
        <div
          v-else-if="question.type === 'Programming'"
          class="cc-exam__link u-pl30 u-pb14"
        >
          <a class="t-primary" :href="question.link" target="_blank">
            题目链接
          </a>
        </div>
        <div v-else-if="question.type === 'Qa'" class="u-mb16">
          <el-input
            type="textarea"
            :rows="5"
            :disabled="isDisabled"
            v-model="cForm.model.questions[questionIndex].answer"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script src="./script.js"></script>
