import { RouterView } from "vue-router";

export const worksRoute = {
  path: "works",
  component: <RouterView />,
  children: [
    {
      path: "classes",
      component: () => import("@/views/uc/common/classes/index.vue"),
    },
    {
      path: "",
      component: () => import("@/views/uc/common/works/wrap.vue"),
    },
    {
      path: "exams/:examId/results/:studentId",
      component: () =>
        import("@/views/uc/resources/exam-results/detail/wrap.vue"),
    },
  ],
};
