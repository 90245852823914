import { onMounted, reactive, ref } from "vue";
import $ from "jquery";
import { filesApi } from "@/apis/client/files";
import { useAuth } from "@/composables/use-auth";
import { useConsts } from "@/composables/use-consts";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({
        steps: [],
      }),
    },
  },
  emits: ["change", "save"],
  setup(props, context) {
    const { CdnUrl, OfficeServiceUrl } = useConsts();

    const { isTeacher } = useAuth();

    const current = ref(0);

    const imageSrc = ref("");

    const officeViewer = ref(null);

    const cFixedVideo = reactive({
      visible: false,
      src: "",
    });

    onMounted(() => {
      const floatTrigger = `<div class="float-trigger">
        <i class="c-iconfont c-iconfont--fix"></i>
      </div>`;

      $("body").on("click", ".is-rich-text img, .c-exam img", function () {
        imageSrc.value = $(this).attr("src");
      });

      $("body").on("mouseenter", ".is-rich-text p", function () {
        const $this = $(this);

        if ($this.find(">video")[0]) {
          $this.attr("class", "is-video").append(
            $(floatTrigger).on("click", () => {
              cFixedVideo.visible = true;
              cFixedVideo.src = $this.find("video").attr("src");
            })
          );
        }
      });

      $("body").on("mouseleave", ".is-rich-text p", function () {
        if ($(this).find(">video")[0]) {
          $(this).find(".float-trigger").remove();
        }
      });

      $("body").on("click", ".is-courseware p", async function () {
        const id = $(this).data("src");
        const { date, uuid, ext } = await filesApi.get({ id });
        const url = `${CdnUrl}/${date}/${uuid}.${ext}`;

        officeViewer.value.show({ serviceUrl: OfficeServiceUrl, src: url });
      });
    });

    const gotoStep = (index) => {
      current.value = index;
      context.emit("change", index);
    };

    const closeFixedVideo = () => {
      cFixedVideo.visible = false;
      cFixedVideo.src = "";
    };

    return {
      isTeacher,
      current,
      imageSrc,
      officeViewer,
      cFixedVideo,
      gotoStep,
      closeFixedVideo,
    };
  },
};
