import { keyMirror } from "jt-helpers";
import { ElMessage } from "element-plus";

const state = {
  history: [],
};

const types = keyMirror({
  AddHistory: null,
  DeleteHistory: null,
});

const mutations = {
  [types.AddHistory](state, { history }) {
    state.history.push(history);
  },
  [types.DeleteHistory](state, { index }) {
    state.history.splice(index, 1);
  },
};

const actions = {
  async addHistory({ state, commit }, { history }) {
    if (state.history.find(({ dataUrl }) => dataUrl === history.dataUrl)) {
      ElMessage.error("请不要重复保存");
      return null;
    } else {
      commit(types.AddHistory, { history });
      return { history };
    }
  },
  async deleteHistory({ commit }, { index }) {
    commit(types.DeleteHistory, { index });
    return null;
  },
};

export const decisionTree = {
  namespaced: true,
  state,
  mutations,
  actions,
};
