import EnumSelect from "element-plus-admin/components/enum-select/index.vue";
import List from "element-plus-admin/components/list/index.vue";
import ListImage from "element-plus-admin/components/list/components/image/index.vue";
import OfficeViewer from "element-plus-admin/components/file-viewer/components/office-viewer/index.vue";
import ResourceSelect from "element-plus-admin/components/resource-select/index.vue";
import Upload from "element-plus-admin/components/upload/index.vue";
import OrderInput from "element-plus-admin/components/order-input/index.vue";
import Editor from "element-plus-admin/components/editor/index.vue";
import Account from "@/components/account/index.vue";
import ContentItem from "@/components/content-item/index.vue";
import HardwareIpInput from "@/components/hardware-ip-input/index.vue";
import Html from "@/components/html/index.vue";
import Image from "@/components/image/index.vue";
import ImportXlsx from "@/components/import-xlsx/index.vue";
import Introduction from "@/components/introduction/index.vue";
import MainContainer from "@/components/main-container/index.vue";
import Ppt from "@/components/ppt/index.vue";
import Question from "@/components/question/index.vue";
import Steps from "@/components/steps/index.vue";
import Uploader from "@/components/uploader/index.vue";
import VideoViewer from "@/components/video-viewer/index.vue";
import ViewActions from "@/components/view-actions/index.vue";
import Webcam from "@/components/webcam/index.vue";
import Content from "@/components/content/index.vue";
import ExamQuestionList from "@/components/exam-question-list/index.vue";
import {
  Eleme,
  Right,
  ZoomIn,
  Delete,
  ArrowRight,
} from "@element-plus/icons-vue";

export const useComponents = (app) => {
  app.component("c-enum-select", EnumSelect);
  app.component("c-list", List);
  app.component("c-list-image", ListImage);
  app.component("c-office-viewer", OfficeViewer);
  app.component("c-resource-select", ResourceSelect);
  app.component("c-upload", Upload);
  app.component("c-order-input", OrderInput);
  app.component("c-editor", Editor);
  app.component("c-account", Account);
  app.component("c-content-item", ContentItem);
  app.component("c-hardware-ip-input", HardwareIpInput);
  app.component("c-html", Html);
  app.component("c-image", Image);
  app.component("c-import-xlsx", ImportXlsx);
  app.component("c-introduction", Introduction);
  app.component("c-main-container", MainContainer);
  app.component("c-ppt", Ppt);
  app.component("c-question", Question);
  app.component("c-steps", Steps);
  app.component("c-uploader", Uploader);
  app.component("c-video-viewer", VideoViewer);
  app.component("c-view-actions", ViewActions);
  app.component("c-webcam", Webcam);
  app.component("c-content", Content);
  app.component("c-exam-question-list", ExamQuestionList);
  app.component("el-icon-right", Right);
  app.component("el-icon-eleme", Eleme);
  app.component("el-icon-zoom-in", ZoomIn);
  app.component("el-icon-delete", Delete);
  app.component("el-icon-arrow-right", ArrowRight);
};
