<template>
  <el-upload
    :before-upload="beforeUpload"
    :show-file-list="false"
    style="display: inline-block; margin-left: 10px; margin-right: 10px"
  >
    <el-button>导入数据</el-button>
  </el-upload>
</template>

<script src="./script.js"></script>
