<template>
  <div class="c-introduction">
    <div
      v-show="!cDrawer.visible"
      class="c-introduction__trigger"
      @click="cDrawer.visible = true"
    >
      <el-icon-arrow-right class="c-introduction__arrow" />
    </div>
    <el-drawer
      direction="ltr"
      :with-header="false"
      show-close
      size="33%"
      v-model="cDrawer.visible"
    >
      <h2 class="c-introduction__title">{{ title }}</h2>
      <div class="c-introduction__body">
        <slot name="body" />
      </div>
    </el-drawer>
  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
