import { useList } from "element-plus-admin/components/list/composables/use-list";
import { useEnums } from "element-plus-admin/composables/use-enums";
import { examQuestionsApi } from "@/apis/client/exam-questions";
import { onMounted, reactive } from "vue";

export default {
  setup() {
    const { enums } = useEnums();

    const {
      list,
      currentPage,
      cFilters,
      render,
      reRender,
      onPageChange: onListPageChange,
      search,
    } = useList({
      routeMode: false,
      autoRender: false,
      api: examQuestionsApi,
      extraQuery: {
        include: [{ model: "Exam", as: "exam" }],
        order: [["order", "ASC"]],
      },
      filters: {
        model: {
          copy: { $ne: 1 },
          knowledgeType: "",
          question: { $like: "" },
        },
        rules: {},
      },
    });

    const state = reactive({
      checkAll: false,
      checkedItems: {},
    });

    onMounted(async () => {
      cFilters.model = {
        copy: { $ne: 1 },
        knowledgeType: "",
        question: { $like: "" },
      };

      await render();
    });

    const onCheckAllChange = () => {
      list.items.forEach((item) => {
        state.checkedItems[item.id + ""] = state.checkAll;
      });
    };

    const onCheckChange = () => {
      let isAllChecked = true;

      list.items.forEach((item) => {
        if (!state.checkedItems[item.id + ""]) {
          isAllChecked = false;
        }
      });

      state.checkAll = isAllChecked;
    };

    const onPageChange = async (current) => {
      await onListPageChange(current);
      onCheckChange();
    };

    return {
      enums,
      list,
      currentPage,
      cFilters,
      state,
      reRender,
      onCheckAllChange,
      onPageChange,
      search,
      onCheckChange,
    };
  },
};
