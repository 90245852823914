import { courseRoute } from "./course";
import { homeRoute } from "./home";
import { loginRoute } from "./login";
import { platformRoute } from "./platform";
import { logoutRoute } from "./logout";
import { serviceRoute } from "./service";

export const webRoutes = [
  courseRoute,
  homeRoute,
  platformRoute,
  loginRoute,
  logoutRoute,
  serviceRoute,
];
