import * as Xlsx from "xlsx";

export default {
  emits: ["ok"],
  setup(props, context) {
    const beforeUpload = (file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const ab = e.target.result;
        const wb = Xlsx.read(new Uint8Array(ab), { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = Xlsx.utils.sheet_to_json(ws, { header: 1 });

        context.emit("ok", data);
      };

      reader.readAsArrayBuffer(file);

      return false;
    };

    return {
      beforeUpload,
    };
  },
};
