export const Axis = [
  {
    label: "X轴",
    value: "X",
  },
  {
    label: "Y轴",
    value: "Y",
  },
];
