import { computed } from "vue";
import { store } from "@/store";

export const useAuth = () => {
  const { dispatch, state } = store;

  const user = computed(() => {
    const { user, currentSchool } = state.auth;

    return currentSchool.id
      ? { ...user, school: currentSchool, originalSchool: user.school || {} }
      : { ...user, originalSchool: user.school || {} };
  });

  const menus = computed(() => state.auth.menus);

  const getHeaders = () => {
    return { Authorization: `Bearer ${user.value.token}` };
  };

  const loggedIn = () => !!user.value.token;

  const login = (options) => dispatch("auth/login", options);

  const logout = () => dispatch("auth/logout");

  const setSchool = (options) => dispatch("auth/setSchool", options);

  const isTeacher = computed(() => user.value.type === "Teacher");

  const isStudent = computed(() => user.value.type === "Student");

  const isSchoolManager = computed(() => user.value.type === "SchoolManager");

  const schoolId = computed(() => user.value.school.id);

  return {
    user,
    menus,
    getHeaders,
    loggedIn,
    login,
    logout,
    setSchool,
    isTeacher,
    isStudent,
    isSchoolManager,
    schoolId,
  };
};
