import { learnRoute } from "./learn";
import { resourcesRoute } from "./resources";
import { schoolRoute } from "./school";
import { teachRoute } from "./teach";
import { dataRoute } from "./data";
import { examsRoute } from "./exams";
import { experimentsRoute } from "./experiments";
import { gamesRoute } from "./games";
import { homeRoute } from "./home";
import { productsRoute } from "./products";
import { worksRoute } from "./works";

export const ucRoutes = [
  learnRoute,
  resourcesRoute,
  schoolRoute,
  teachRoute,
  dataRoute,
  examsRoute,
  experimentsRoute,
  gamesRoute,
  homeRoute,
  productsRoute,
  worksRoute,
];
