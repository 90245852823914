import { reactive } from "vue";
import Login from "./components/login/index.vue";
import { useAuth } from "@/composables/use-auth";
import { useRouter } from "vue-router";
import { useConsts } from "@/composables/use-consts";

export default {
  components: {
    "b-login": Login,
  },
  setup() {
    const { ScratchUrl } = useConsts();

    const router = useRouter();

    const { loggedIn, user } = useAuth();

    const cDialog = reactive({
      visible: false,
      data: {},
    });

    const cLoginTabs = reactive({
      current: "first",
    });

    const open = async ({ type, url, route }, user = {}) => {
      switch (true) {
        case type === "scratch":
          window.open(`${ScratchUrl}/?token=${user.token}`);
          break;

        case !!url:
          window.open(url);
          break;

        case !!route:
          await router.push(route);
          break;

        default:
          await router.push({ path: "/uc" });
          break;
      }
    };

    const show = async ({ type, url, route } = {}) => {
      cDialog.data = { type, url, route };

      if (loggedIn()) {
        await open(cDialog.data, user.value);
      } else {
        cDialog.visible = true;
      }
    };

    const login = async ({ user }) => {
      await open(cDialog.data, user);
    };

    return {
      cDialog,
      cLoginTabs,
      show,
      login,
    };
  },
};
