import { keyMirror } from "jt-helpers";
import { publicAccountsApi } from "@/apis/public/accounts";
import { createApi } from "element-plus-admin/utils/create-api";

const state = {
  user: {
    id: "",
    type: "",
    account: "",
    name: "",
    gender: 0,
    token: "",
    school: {},
  },
  currentSchool: {},
};

const types = keyMirror({
  SetUser: null,
  SetSchool: null,
});

const mutations = {
  [types.SetUser](state, user) {
    state.user = user;
  },
  [types.SetSchool](state, school) {
    state.currentSchool = school;
  },
};

const actions = {
  async loginByToken({ commit }, token) {
    const data = await createApi({
      url: "/client/users",
      headers: () => ({ Authorization: `Bearer ${token}` }),
    }).get({ joinUrl: "/userInfo" });

    commit(types.SetUser, { ...data, token });
  },
  async login({ commit }, data) {
    const { user, token } = await publicAccountsApi.post({
      action: "clientLogin",
      body: data,
    });

    commit(types.SetUser, { ...user, token });

    return { ...user, token };
  },
  logout({ commit }) {
    commit(types.SetUser, state.user);
    return null;
  },
  setSchool({ commit }, data) {
    commit(types.SetSchool, data);
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
};
