import { RouterView } from "vue-router";

export const platformRoute = {
  path: "platform",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/web/platform/home/index.vue"),
    },
    {
      path: "coding",
      component: () => import("@/views/web/platform/views/coding/index.vue"),
    },
    {
      path: "ai",
      component: () => import("@/views/web/platform/views/ai/index.vue"),
    },
    {
      path: "teach",
      component: () => import("@/views/web/platform/views/teach/index.vue"),
    },
  ],
};
