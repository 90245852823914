<template>
  <canvas
    class="thumb"
    width="180"
    height="180"
    :id="`webcamCanvas${pk}`"
    style="display: none"
  ></canvas>
  <el-popover
    placement="bottom"
    :width="180"
    trigger="click"
    :disabled="!cWebcam.available"
    v-model:visible="cPopover.visible"
  >
    <template #reference>
      <div class="c-upload__button u-mb10" @click="showPopover">
        <i class="c-iconfont c-iconfont--camera-filled"></i>
        <p>本地摄像头</p>
      </div>
    </template>
    <video
      autoplay
      playsinline
      muted
      :id="`webcam${pk}`"
      width="180"
      height="180"
      style="background-color: #eff1f6"
    ></video>
    <el-button
      class="c-button--full"
      style="margin-top: 2px"
      @click="takePhoto"
    >
      拍照
    </el-button>
  </el-popover>
</template>

<script src="./script.js"></script>
