import { RouterView } from "vue-router";

export const gamesRoute = {
  path: "games",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/uc/games/list/index.vue"),
    },
    {
      path: ":id",
      component: () => import("@/views/uc/games/detail/index.vue"),
    },
    {
      path: ":id/form",
      component: () => import("@/views/uc/games/form/index.vue"),
    },
  ],
};
