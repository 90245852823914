import { RouterView } from "vue-router";

export const examsRoute = {
  path: "exams",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/uc/resources/exams/list/wrap.vue"),
    },
    {
      path: ":examId/questions",
      component: () =>
        import("@/views/uc/resources/exam-questions/list/wrap.vue"),
    },
    {
      path: ":examId/results",
      component: () =>
        import("@/views/uc/resources/exam-results/list/wrap.vue"),
    },
    {
      path: ":examId/results/:studentId",
      component: () =>
        import("@/views/uc/resources/exam-results/detail/wrap.vue"),
    },
    {
      path: ":examId/result-reports",
      component: () =>
        import("@/views/uc/resources/exam-results/reports/wrap.vue"),
    },
  ],
};
