import { reactive } from "vue";

export default {
  props: {
    pk: {
      type: [String, Number],
    },
    src: {
      type: String,
    },
    width: {
      type: String,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const cActions = reactive({
      visible: false,
    });

    const cImageViewer = reactive({
      visible: false,
    });

    return {
      cActions,
      cImageViewer,
    };
  },
};
