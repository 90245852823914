import { reactive } from "vue";

export const useForm = ({ questionsList, resultsDetail }) => {
  const cForm = reactive({
    model: {
      questions: [],
    },
  });

  const onOptionChange = async (questionIndex, optionIndex) => {
    const question = questionsList.items[questionIndex];
    const options = cForm.model.questions[questionIndex].options;

    if (["SingleSelect", "TrueOrFalse"].includes(question.type)) {
      cForm.model.questions[questionIndex].options = [
        false,
        false,
        false,
        false,
      ].map((option, index) => !options[optionIndex] && optionIndex === index);
    } else {
      cForm.model.questions[questionIndex].options[optionIndex] =
        !options[optionIndex];
    }
  };

  const render = () => {
    cForm.model.questions = questionsList.items.map((question) => {
      const answer = (resultsDetail.answers || []).find(
        ({ questionId }) => questionId === question.id
      );

      return {
        options: ["SingleSelect", "MultipleSelect", "TrueOrFalse"].includes(
          question.type
        )
          ? ["A", "B", "C", "D"].map(
              (option) =>
                !!(answer && answer.answer.split(",").includes(option))
            )
          : [],
        answer: answer ? answer.answer : "",
      };
    });
  };

  const getAnswers = () => {
    return cForm.model.questions.map(({ options, answer }, index) => {
      const question = questionsList.items[index];

      return {
        questionId: question.id,
        answer: ["SingleSelect", "TrueOrFalse", "MultipleSelect"].includes(
          question.type
        )
          ? ["A", "B", "C", "D"]
              .filter((item, index) => options[index])
              .join(",")
          : answer,
      };
    });
  };

  return {
    cForm,
    onOptionChange,
    render,
    getAnswers,
  };
};
