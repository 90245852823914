<template>
  <el-dialog title="访问硬件" width="400px" v-model="cDialog.visible">
    <el-form
      ref="formRef"
      :model="cForm.model"
      :rules="cForm.rules"
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="硬件 IP" prop="ip">
        <el-input placeholder="请输入硬件 IP" v-model.trim="cForm.model.ip" />
      </el-form-item>
      <el-form-item>
        <el-button @click="cDialog.visible = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script src="./script.js"></script>
