import { RouterView } from "vue-router";

export const experimentsRoute = {
  path: "experiments",
  component: <RouterView />,
  children: [
    {
      path: "list",
      component: () => import("@/views/uc/home/index.vue"),
    },
    {
      path: "detail",
      component: () => import("@/views/uc/experiments/detail/index.vue"),
    },
    {
      path: "steps",
      component: () => import("@/views/uc/experiments/steps/index.vue"),
    },
    {
      path: "dog-cat-recognition",
      component: () =>
        import("@/views/uc/experiments/dog-cat-recognition/index.vue"),
    },
    {
      path: "farmer-pass-river",
      component: () =>
        import("@/views/uc/experiments/farmer-pass-river/index.vue"),
    },
    {
      path: "sudoku",
      component: () => import("@/views/uc/experiments/sudoku/index.vue"),
    },
    {
      path: "garbage-classification",
      component: () =>
        import("@/views/uc/experiments/garbage-classification/index.vue"),
    },
    {
      path: "knn",
      component: () => import("@/views/uc/experiments/knn/index.vue"),
    },
    {
      path: "custom-knn",
      component: () => import("@/views/uc/experiments/custom-knn/index.vue"),
    },
    {
      path: "custom-dt",
      component: () => import("@/views/uc/experiments/custom-dt/index.vue"),
    },
    {
      path: "weather",
      component: () => import("@/views/uc/experiments/weather/index.vue"),
    },
    {
      path: "hanoi",
      component: () => import("@/views/uc/experiments/hanoi/index.vue"),
    },
    {
      path: "n-queens",
      component: () => import("@/views/uc/experiments/n-queens/index.vue"),
    },
  ],
};
