export const Difficulty = [
  {
    label: "简单",
    value: "Easy",
  },
  {
    label: "一般",
    value: "Medium",
  },
  {
    label: "困难",
    value: "Hard",
  },
];
