<template>
  <c-list
    :total="list.total"
    :current-page="currentPage"
    @page-change="onPageChange"
  >
    <template #operations>
      <el-button type="primary" @click="search">刷新</el-button>
    </template>
    <template #filters>
      <el-form
        :ref="cFilters.ref"
        :model="cFilters.model"
        :rules="cFilters.rules"
        inline
      >
        <el-form-item prop="type">
          <c-enum-select
            clearable
            placeholder="请选择题型"
            :items="enums.ExamQuestionType"
            v-model:value="cFilters.model.type"
          />
        </el-form-item>
        <el-form-item prop="knowledgeType">
          <c-enum-select
            clearable
            placeholder="请选择知识点类型"
            :items="enums.KnowledgeType"
            v-model:value="cFilters.model.knowledgeType"
          />
        </el-form-item>
        <el-form-item prop="题目">
          <el-input
            clearable
            placeholder="请输入题目"
            v-model.trim="cFilters.model.question.$like"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table :data="list.items" height="480" stripe>
      <el-table-column width="40">
        <template #header>
          <el-checkbox v-model="state.checkAll" @change="onCheckAllChange" />
        </template>
        <template #default="{ row }">
          <el-checkbox
            :key="row.id"
            v-model="state.checkedItems[row.id]"
            @change="onCheckChange"
          />
        </template>
      </el-table-column>
      <el-table-column label="考试名称" width="230">
        <template #default="{ row }">
          {{ row.exam ? row.exam.name : "" }}
        </template>
      </el-table-column>
      <el-table-column label="题型" prop="name" width="100">
        <template #default="{ row }">
          {{
            $helpers.getItem(enums.ExamQuestionType, "value", row.type)["label"]
          }}
        </template>
      </el-table-column>
      <el-table-column label="知识点类型" width="100">
        <template #default="{ row }">
          {{
            $helpers.getItem(enums.KnowledgeType, "value", row.knowledgeType)[
              "label"
            ]
          }}
        </template>
      </el-table-column>
      <el-table-column label="难易程度" width="100">
        <template #default="{ row }">
          {{
            $helpers.getItem(enums.Difficulty, "value", row.difficulty)["label"]
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目" prop="question">
        <template #default="{ row }">
          {{ $helpers.clearHtmlTags(row.question) }}
        </template>
      </el-table-column>
      <el-table-column label="分数" prop="score" width="60" />
    </el-table>
  </c-list>
</template>

<script src="./script.js"></script>
