import { RouterView } from "vue-router";
import { classesRoute } from "./classes";
import { festivalsRoute } from "./festivals";
import { studentsRoute } from "./students";

export const schoolRoute = {
  path: "school",
  component: <RouterView />,
  children: [classesRoute, festivalsRoute, studentsRoute],
};
