import { RouterView } from "vue-router";

export const classesRoute = {
  path: "classes",
  component: <RouterView />,
  children: [
    {
      path: ":type",
      component: () => import("@/views/uc/school/classes/list/wrap.vue"),
    },
    {
      path: ":type/:classId/schedules",
      component: () =>
        import("@/views/uc/school/class-schedules/list/wrap.vue"),
    },
    {
      path: ":type/:classId/class-students",
      component: () => import("@/views/uc/school/class-students/list/wrap.vue"),
    },
  ],
};
