import { createRouter, createWebHashHistory, RouterView } from "vue-router";
import { publicRoutes } from "./routes/public";
import { webRoutes } from "./routes/web";
import { ucRoutes } from "./routes/uc";
import { store } from "@/store";

const routes = [
  {
    path: "/",
    component: () => import("@/components/layout/index.vue"),
    children: [
      {
        path: "/",
        component: <RouterView />,
        children: webRoutes,
      },
      {
        path: "/uc",
        component: <RouterView />,
        children: ucRoutes,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/public",
    component: <RouterView />,
    children: publicRoutes,
  },
  {
    path: "/:matchOthers(.*)*",
    component: () => import("@/views/404/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    await store.dispatch("auth/loginByToken", to.query.token);
    next();
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.user.token) {
      await store.dispatch("auth/logout");

      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export { router };
