import { reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import { examQuestionsApi } from "@/apis/client/exam-questions";

export default {
  props: {
    examId: Number,
  },
  emits: ["cancel", "ok"],
  setup(props, context) {
    const cDialog = reactive({
      visible: false,
    });

    const examQuestionList = ref(null);

    const show = async () => {
      cDialog.visible = true;
    };

    const submit = async () => {
      const { checkedItems } = examQuestionList.value.state;
      const checkedIds = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map((item) => parseInt(item, 10));

      if (checkedIds.length) {
        const { items } = await examQuestionsApi.get({
          query: {
            where: { id: { $in: checkedIds } },
            attributes: ["id", "type", "question", "options", "answer"],
          },
        });
        cDialog.visible = false;
        context.emit("ok", { type: "Exam", value: items });
      } else {
        ElMessage.error("未选中题目");
      }
    };

    return {
      cDialog,
      examQuestionList,
      show,
      submit,
    };
  },
};
