import { RouterView } from "vue-router";

export const experimentsRoute = {
  path: "experiments",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/uc/resources/experiments/list/wrap.vue"),
    },
    {
      path: ":experimentId/steps",
      component: () =>
        import("@/views/uc/resources/experiments/list/steps/wrap.vue"),
    },
    {
      path: ":experimentId/experiment-projects",
      component: () =>
        import("@/views/uc/resources/experiment-projects/list/wrap.vue"),
    },
  ],
};
