import helpers from "jt-helpers";
import { useConsts } from "@/composables/use-consts";

export const useHelpers = () => {
  const consts = useConsts();

  return {
    ...helpers,
    clearHtmlTags(html) {
      return html.replace(/<[^>]+>/g, "");
    },
    getImageUrl({ id, width, height }) {
      const params = (() => {
        switch (true) {
          case !!width && !!height: {
            const resizedWidth = parseInt(width * 1.2 + "");
            const resizedHeight = parseInt(height * 1.2 + "");

            return `?x-oss-process=image/resize,w_${resizedWidth},h_${resizedHeight}`;
          }

          default:
            return "";
        }
      })();

      return `${consts.ApiUrl}/public/files/${id}${params}`;
    },
    getFileUrl({ id }) {
      return `${consts.ApiUrl}/public/files/${id}`;
    },
    openWindow(url) {
      window.open(url);
    },
    download(url, fileName) {
      const xhr = new XMLHttpRequest();
      const urlSplit = url.split("/");

      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        const url = window.URL.createObjectURL(xhr.response);
        const a = document.createElement("a");

        a.href = url;
        a.download = fileName || urlSplit[urlSplit.length - 1];
        a.click();
      };

      xhr.send();
    },
    formatHtml(html) {
      return html
        ? html.replace(/jx-api\.fzu-aiedu\.com/g, "api.faie.cn")
        : null;
    },
  };
};
