<template>
  <router-view />
</template>

<script>
import { onMounted } from "vue";
import { useEnums } from "element-plus-admin/composables/use-enums";
import { useResources } from "@/composables/use-resources";
import { publicAdsApi } from "@/apis/public/ads";

export default {
  setup() {
    const { getEnums } = useEnums();
    const { getList: getAdsList } = useResources({ resource: "ads" });

    onMounted(async () => {
      await getEnums();
      await getAdsList({
        request: () => publicAdsApi.get({}),
      });
    });
  },
};
</script>
