<template>
  <el-dialog
    custom-class="c-account"
    destroy-on-close
    append-to-body
    width="600px"
    v-model="cDialog.visible"
  >
    <div class="b-wrap">
      <div class="b-banner"></div>
      <div class="b-login-tabs">
        <el-tabs v-model="cLoginTabs.current">
          <el-tab-pane label="账号登录" name="first">
            <b-login @ok="login" @close-dialog="cDialog.visible = false" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-dialog>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
