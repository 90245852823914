<template>
  <ul class="c-view-actions">
    <li>
      <i class="c-iconfont c-iconfont--view"></i>
      2590
    </li>
    <li>
      <i class="c-iconfont c-iconfont--good"></i>
      8
    </li>
    <li>
      <i class="c-iconfont c-iconfont--start"></i>
      11
    </li>
  </ul>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
