import { reactive, ref } from "vue";
import { useHelpers } from "@/composables/use-helpers";
import { useEnums } from "element-plus-admin/composables/use-enums";
import { useFormDialog } from "element-plus-admin/composables/use-form-dialog";
import { aliCloudOssApi } from "@/apis/client/ali-cloud-oss";
import { filesApi } from "@/apis/client/files";
import { UploadTo } from "element-plus-admin/enums/upload-to";

export default {
  emits: ["cancel", "ok"],
  setup(props, context) {
    const formRef = ref(null);

    const { deepCopy } = useHelpers();

    const { enums } = useEnums();

    const cDialog = reactive({
      visible: false,
    });

    const initialModel = {
      value: "",
    };

    const cForm = reactive({
      model: deepCopy(initialModel),
      rules: {},
    });

    const { show, validate, validateField } = useFormDialog({
      cDialog,
      cForm,
      formRef,
      initialModel,
    });

    const submit = async () => {
      const { model } = await validate();

      if (model.value) {
        cDialog.visible = false;
        context.emit("ok", { type: "RichText", value: model.value });
      }
    };

    return {
      aliCloudOssApi,
      filesApi,
      UploadTo,
      enums,
      formRef,
      cDialog,
      cForm,
      show,
      validateField,
      submit,
    };
  },
};
