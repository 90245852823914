import RichText from "./components/rich-text/index.vue";
import Exam from "./components/exam/index.vue";
import Courseware from "./components/courseware/index.vue";
import Item from "./components/item/index.vue";
import { ref, watch } from "vue";
import { useEnums } from "element-plus-admin/composables/use-enums";
import draggable from "vuedraggable";

export default {
  components: {
    "cc-rich-text": RichText,
    "cc-exam": Exam,
    "cc-courseware": Courseware,
    "cc-item": Item,
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    showArea: {
      type: String,
      default: "sidebar",
    },
  },
  emits: ["update:value"],
  setup(props, context) {
    const { enums } = useEnums();

    const richText = ref(null);

    const exam = ref(null);

    const courseware = ref(null);

    const content = ref([]);

    const insertIndex = ref(-1);

    const updateIndex = ref(-1);

    const dragging = ref(false);

    watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal.length) {
          if (!newVal.length) content.value = [];
        } else {
          if (newVal.length) content.value = newVal;
        }
      }
    );

    const onCancel = () => {
      insertIndex.value = -1;
      updateIndex.value = -1;
    };

    const onOk = (model) => {
      model.createdAt = new Date().getTime();

      if (insertIndex.value >= 0) {
        content.value.splice(insertIndex.value + 1, 0, model);
        insertIndex.value = -1;
      } else if (updateIndex.value >= 0) {
        content.value[updateIndex.value] = model;
        updateIndex.value = -1;
      } else {
        content.value.push(model);
      }

      context.emit("update:value", content);
    };

    const onDragStart = () => {
      dragging.value = true;
    };

    const onDragEnd = () => {
      dragging.value = false;
      context.emit("update:value", content);
    };

    const insert = (type, index) => {
      switch (type) {
        case "RichText":
          richText.value.show();
          break;

        case "Exam":
          exam.value.show();
          break;

        case "Courseware":
          courseware.value.show();
          break;

        default:
          break;
      }

      insertIndex.value = index;
    };

    const update = ({ type, value }, index) => {
      switch (type) {
        case "RichText":
          richText.value.show({ value });
          break;

        case "Exam":
          exam.value.show({ value });
          break;

        case "Courseware":
          courseware.value.show({ value: value.id });
          break;

        default:
          break;
      }

      updateIndex.value = index;
    };

    const remove = (index) => {
      content.value.splice(index, 1);
    };

    return {
      enums,
      richText,
      exam,
      courseware,
      content,
      dragging,
      onCancel,
      onOk,
      onDragStart,
      onDragEnd,
      insert,
      update,
      remove,
    };
  },
};
