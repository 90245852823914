import { RouterView } from "vue-router";

export const dataRoute = {
  path: "data",
  component: <RouterView />,
  children: [
    {
      path: "school",
      component: () => import("@/views/uc/common/data/school"),
    },
    {
      path: "teacher",
      component: () => import("@/views/uc/common/data/teacher"),
    },
    {
      path: "student",
      component: () => import("@/views/uc/common/data/student"),
    },
  ],
};
