export const CourseFile = [
  {
    label: "演示课件",
    value: "showCaseFile",
  },
  {
    label: "课程教案",
    value: "classroomFile",
  },
  {
    label: "视频资料",
    value: "videoFile",
  },
  {
    label: "案例程序",
    value: "programFile",
  },
];
