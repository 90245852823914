import { keyMirror } from "jt-helpers";
import Cookies from "js-cookie";

const domain = "faie.cn";

const state = {
  ips: JSON.parse(Cookies.get("hardwareIps", { domain }) || "[]"),
  connectedIp: Cookies.get("hardwareConnectedIp", { domain }) || "",
};

const types = keyMirror({
  AddIp: null,
  ConnectIp: null,
});

const mutations = {
  [types.AddIp](state, { ip, index }) {
    if (index !== -1) {
      state.ips.splice(index, 1);
    }
    state.ips.unshift(ip);

    Cookies.set("hardwareIps", JSON.stringify(state.ips), { domain });
  },
  [types.ConnectIp](state, { ip }) {
    state.connectedIp = ip;

    Cookies.set("hardwareConnectedIp", ip, { domain });
  },
};

const actions = {
  async connectIp({ state, commit }, { ip }) {
    const index = state.ips.findIndex((item) => item === ip);

    commit(types.AddIp, { ip, index });
    commit(types.ConnectIp, { ip });

    return { ip };
  },
};

export const hardware = {
  namespaced: true,
  state,
  mutations,
  actions,
};
