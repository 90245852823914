<template>
  <div class="c-steps__wrap">
    <div class="c-steps__btn">
      <div class="c-steps__name">{{ detail.name }}</div>
      <ul v-if="detail.steps" class="c-steps__steps">
        <li
          v-for="(item, index) in detail.steps"
          :key="index"
          :class="{ 'is-active': index === current }"
          @click="gotoStep(index)"
        >
          {{ index + 1 }}
        </li>
      </ul>
    </div>
    <div
      v-if="detail.steps && current !== detail.steps.length - 1"
      class="c-steps__btn t-warning"
      @click="gotoStep(current + 1)"
    >
      下一步
    </div>
    <div v-if="false" class="c-steps__btn t-warning">完成本题</div>
    <div v-if="false" class="c-steps__btn t-warning">查看答案</div>
    <div v-if="false" class="c-steps__btn t-warning">发布作品</div>
    <template v-if="$route.query.from === 'works'">
      <div
        v-if="isTeacher"
        class="c-steps__btn bg-warning t-white"
        @click="$emit('save')"
      >
        完成评分
      </div>
      <div
        v-else
        class="c-steps__btn bg-warning t-white"
        @click="$emit('save')"
      >
        提交作业
      </div>
    </template>
  </div>
  <el-image-viewer
    v-if="imageSrc"
    :url-list="[imageSrc]"
    teleported
    @close="imageSrc = ''"
  />
  <c-office-viewer ref="officeViewer" />
  <div v-if="cFixedVideo.visible" class="c-steps__fixed-video">
    <video :src="cFixedVideo.src" controls="controls"></video>
    <div class="cc-close" @click="closeFixedVideo">
      <i class="c-iconfont c-iconfont--close"></i>
    </div>
  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
