import * as tf from "@tensorflow/tfjs";
import { nextTick, onMounted, reactive } from "vue";
import { ElMessage } from "element-plus";

export default {
  props: {
    pk: Number,
  },
  setup(props, context) {
    let webcam;

    const cPopover = reactive({
      visible: false,
    });

    const cWebcam = reactive({
      available: false,
    });

    const getDaraUrl = (image, canvas) => {
      const [width, height] = [180, 180];
      const ctx = canvas.getContext("2d");
      const imageData = new ImageData(width, height);
      const data = image.dataSync();

      for (let i = 0; i < height * width; ++i) {
        const j = i * 4;

        imageData.data[j + 0] = (data[i * 3 + 0] + 1) * 127;
        imageData.data[j + 1] = (data[i * 3 + 1] + 1) * 127;
        imageData.data[j + 2] = (data[i * 3 + 2] + 1) * 127;
        imageData.data[j + 3] = 255;
      }

      ctx.putImageData(imageData, 0, 0);

      context.emit("ok", canvas.toDataURL("image/jpeg", 1));
    };

    onMounted(async () => {
      await nextTick(async () => {
        const webcamElement = document.getElementById(`webcam${props.pk}`);

        try {
          webcam = await tf.data.webcam(webcamElement);
          cWebcam.available = true;
        } catch (e) {
          cWebcam.available = false;
        }
      });
    });

    const showPopover = () => {
      if (!cWebcam.available) {
        ElMessage.error("请检查您的浏览器权限是否开启，摄像头是否连接正常");
      }
    };

    const takePhoto = async () => {
      const img = await webcam.capture();
      const processedImg = tf.tidy(() =>
        img.expandDims(0).toFloat().div(127).sub(1)
      );

      img.dispose();

      getDaraUrl(
        processedImg,
        document.getElementById(`webcamCanvas${props.pk}`)
      );
    };

    return {
      cPopover,
      cWebcam,
      showPopover,
      takePhoto,
    };
  },
};
