import { RouterView } from "vue-router";
import { classesRoute } from "./classes";
import { classroomRoute } from "./classroom";
import { coursesRoute } from "./courses";

export const teachRoute = {
  path: "teach",
  component: <RouterView />,
  children: [classesRoute, classroomRoute, coursesRoute],
};
