<template>
  <div
    class="c-image"
    :class="`w${width}`"
    @mouseenter="cActions.visible = true"
    @mouseleave="cActions.visible = false"
  >
    <el-image :src="src" fit="cover" />
    <div v-show="cActions.visible" class="c-image__overlay"></div>
    <div v-show="cActions.visible" class="c-image__actions">
      <span title="查看" @click="cImageViewer.visible = true">
        <el-icon-zoom-in class="c-image__icon" />
      </span>
      <span v-if="showDelete" title="删除" @click="$emit('delete', pk)">
        <el-icon-delete class="c-image__icon" />
      </span>
    </div>
    <Teleport to="#app">
      <el-image-viewer
        v-if="cImageViewer.visible"
        :url-list="[src]"
        @close="cImageViewer.visible = false"
      />
    </Teleport>
  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
