import { RouterView } from "vue-router";

export const classesRoute = {
  path: "classes",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/uc/common/classes/index.vue"),
    },
    {
      path: "course-hour-ppt-pages",
      component: () =>
        import("@/views/uc/teach/course-hour-ppt-pages/index.vue"),
    },
  ],
};
