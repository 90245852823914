import { reactive, ref } from "vue";
import { useValidators } from "vue-validation";
import { useHelpers } from "@/composables/use-helpers";

export default {
  setup() {
    const formRef = ref(null);

    const { isRequired } = useValidators();

    const cDialog = reactive({
      visible: false,
    });

    const cForm = reactive({
      model: {},
      rules: {
        ip: [
          isRequired({ label: "硬件 IP" }),
          {
            pattern:
              /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/g,
            message: "请输入合法的 IP 地址",
          },
        ],
      },
    });

    const show = (data) => {
      cForm.model = useHelpers().deepCopy(data);
      cDialog.visible = true;
    };

    const submit = async () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          cDialog.visible = false;

          window.open(`http://${cForm.model.ip}${cForm.model.hardwareUrl}`);
        }
      });
    };

    return {
      formRef,
      cDialog,
      cForm,
      show,
      submit,
    };
  },
};
