import { computed, onMounted, watch } from "vue";
import { useForm } from "./composables/use-form";
import { useEnums } from "element-plus-admin/composables/use-enums";
import { useAuth } from "@/composables/use-auth";

export default {
  props: {
    questionsList: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
    resultsDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["change"],
  setup(props, context) {
    const { user } = useAuth();
    const { enums } = useEnums();

    const { cForm, onOptionChange, render, getAnswers } = useForm({
      questionsList: props.questionsList,
      resultsDetail: props.resultsDetail,
    });

    const isDisabled = computed(() => user.value.type !== "Student");

    watch(
      () => cForm.model.questions,
      () => {
        context.emit("change", getAnswers());
      },
      { deep: true }
    );

    onMounted(async () => {
      await render();
    });

    return {
      enums,
      cForm,
      isDisabled,
      onOptionChange,
      getAnswers,
    };
  },
};
