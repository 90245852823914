<template>
  <el-dialog
    title="富文本"
    v-model="cDialog.visible"
    width="900px"
    destroy-on-close
    @closed="$emit('cancel')"
  >
    <el-form
      ref="formRef"
      :model="cForm.model"
      :rules="cForm.rules"
      label-width="0"
    >
      <el-form-item prop="value">
        <c-editor
          :key="`${cForm.id}:value`"
          :cos-config="{
            cosApi: aliCloudOssApi,
            filesApi,
            uploadTo: UploadTo.AliCloudOss,
            region: 'oss-cn-hangzhou',
            bucket: 'fzznx-faie',
          }"
          style="height: 450px"
          v-model:value="cForm.model.value"
          @change="validateField('value')"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="cDialog.visible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script src="./script.js"></script>
