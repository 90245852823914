import { auth } from "./auth";
import { enums } from "element-plus-admin/store/modules/enums";
import { items } from "element-plus-admin/store/modules/items";
import { decisionTree } from "./decision-tree";
import { experimentSteps } from "./experiment-steps";
import { hardware } from "./hardware";
import { customDt } from "./custom-dt";
import { customKnn } from "./custom-knn";
import { resources } from "./resources";
import { weather } from "./weather";

export default {
  auth,
  enums,
  items,
  decisionTree,
  experimentSteps,
  hardware,
  customDt,
  customKnn,
  resources,
  weather,
};
