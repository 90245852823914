import { reactive, ref } from "vue";
import { useHelpers } from "@/composables/use-helpers";
import { useEnums } from "element-plus-admin/composables/use-enums";
import { useValidators } from "vue-validation";
import { useFormDialog } from "element-plus-admin/composables/use-form-dialog";
import { aliCloudOssApi } from "@/apis/client/ali-cloud-oss";
import { filesApi } from "@/apis/client/files";
import { UploadTo } from "element-plus-admin/enums/upload-to";

export default {
  emits: ["cancel", "ok"],
  setup(props, context) {
    const formRef = ref(null);

    const { deepCopy } = useHelpers();

    const { isRequired } = useValidators();

    const { enums } = useEnums();

    const cDialog = reactive({
      visible: false,
    });

    const initialModel = {};

    const cForm = reactive({
      id: 0,
      model: deepCopy(initialModel),
      rules: {
        value: [isRequired({ message: "请选择文件" })],
      },
    });

    const { show, validate, validateField } = useFormDialog({
      cDialog,
      cForm,
      formRef,
      initialModel,
    });

    const submit = async () => {
      const { model } = await validate();

      context.emit("ok", {
        type: "Courseware",
        value: await filesApi.get({ id: model.value }),
      });

      cDialog.visible = false;
    };

    return {
      aliCloudOssApi,
      filesApi,
      UploadTo,
      enums,
      formRef,
      cDialog,
      cForm,
      show,
      validateField,
      submit,
    };
  },
};
