import { keyMirror } from "jt-helpers";

const state = {
  config: {
    features: ["", ""],
    labels: ["", ""],
  },
};

const types = keyMirror({
  SetConfig: null,
});

const mutations = {
  [types.SetConfig](state, { key, value }) {
    state.config[key] = value;
  },
};

const actions = {
  async setConfig({ commit }, { key, value }) {
    commit(types.SetConfig, { key, value });
  },
};

export const customDt = {
  namespaced: true,
  state,
  mutations,
  actions,
};
