<template>
  <div
    v-if="item.type === 'RichText'"
    class="is-rich-text"
    v-html="item.value"
  ></div>
  <div v-else-if="item.type === 'Exam'" class="is-exam">
    <div v-for="(question, questionIndex) in item.value" :key="questionIndex">
      <h2 v-html="question.question"></h2>
      <template
        v-if="['SingleSelect', 'MultipleSelect'].includes(question.type)"
      >
        <ul>
          <li
            v-for="(option, optionIndex) in ['A', 'B', 'C', 'D']"
            :key="optionIndex"
          >
            <el-checkbox readonly />
            <h3>{{ option }}.</h3>
            <span v-html="question.options[optionIndex]"></span>
          </li>
        </ul>
      </template>
      <template v-if="['TrueOrFalse'].includes(question.type)">
        <ul>
          <li
            v-for="(option, optionIndex) in enums.ExamQuestionTrueOrFalse"
            :key="optionIndex"
          >
            <el-checkbox readonly />
            <h3>{{ option.value }}.</h3>
            <span>{{ option.label }}</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
  <div v-else-if="item.type === 'Courseware'" class="is-courseware">
    <template v-if="showArea === 'sidebar'">
      <p :data-src="item.value.id">
        <i class="c-iconfont c-iconfont--ppt"></i>
        {{ item.value.name }}
      </p>
    </template>
    <template v-else>
      <iframe
        class="is-office"
        :src="getOfficeUrl(item.value)"
        frameborder="0"
      ></iframe>
    </template>
  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
