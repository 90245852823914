import { useEnums } from "element-plus-admin/composables/use-enums";
import { useConsts } from "@/composables/use-consts";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    examResultsDetail: {
      type: Object,
      default: () => ({}),
    },
    showArea: {
      type: String,
      default: "sidebar",
    },
  },
  emits: ["question-change"],
  setup(props, context) {
    const { CdnUrl, OfficeServiceUrl } = useConsts();

    const { enums } = useEnums();

    const getOfficeUrl = ({ date, uuid, ext }) => {
      const url = `${CdnUrl}/${date}/${uuid}.${ext}`;

      return OfficeServiceUrl + url;
    };

    const onQuestionChange = (answers) => {
      context.emit("question-change", answers);
    };

    return {
      enums,
      getOfficeUrl,
      onQuestionChange,
    };
  },
};
