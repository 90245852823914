import { useEnums } from "element-plus-admin/composables/use-enums";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    showArea: {
      type: String,
      default: "sidebar",
    },
  },
  setup() {
    const { enums } = useEnums();

    const getOfficeUrl = ({ date, uuid, ext }) => {
      const serviceUrl = "https://ow365.cn/?i=28208&ssl=1&furl=";
      const url = `https://fzznx-faie.oss-cn-hangzhou.aliyuncs.com/${date}/${uuid}.${ext}`;

      return serviceUrl + url;
    };

    return {
      enums,
      getOfficeUrl,
    };
  },
};
