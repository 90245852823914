import { RouterView } from "vue-router";

export const coursesRoute = {
  path: "courses",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/uc/resources/courses/list/wrap.vue"),
    },
    {
      path: ":courseId/class-hours",
      component: () =>
        import("@/views/uc/resources/course-hours/list/wrap.vue"),
    },
    {
      path: ":courseId/class-hours/:classHourId/course-hour-ppt-pages",
      component: () =>
        import("@/views/uc/resources/course-hour-ppt-pages/wrap.vue"),
    },
  ],
};
