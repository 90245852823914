import { RouterView } from "vue-router";

export const productsRoute = {
  path: "products",
  component: <RouterView />,
  children: [
    {
      path: "list",
      component: () => import("@/views/uc/products/list/index.vue"),
    },
    {
      path: "detail/:id",
      component: () => import("@/views/uc/products/detail/index.vue"),
    },
  ],
};
