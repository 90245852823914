import { computed } from "vue";
import { useStore } from "vuex";

export const useResources = ({ module = "public", resource }) => {
  const { dispatch, state } = useStore();

  const list = computed(() => state.resources[module][resource].list);

  const getList = ({ request }) =>
    dispatch("resources/getList", { module, resource, request });

  return {
    list,
    getList,
  };
};
