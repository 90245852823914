import { keyMirror } from "jt-helpers";

const getState = (resources) => {
  const ret = {};

  resources.forEach((resource) => {
    ret[resource] = {
      list: {
        total: 0,
        items: [],
      },
      detail: {},
    };
  });

  return ret;
};

const state = {
  public: getState(["ads"]),
};

const types = keyMirror({
  SetList: null,
});

const mutations = {
  [types.SetList](state, { module, resource, list }) {
    state[module][resource].list = list;
  },
};

const actions = {
  async getList({ commit }, { module, resource, request }) {
    const list = await request();

    commit(types.SetList, { module, resource, list });

    return { module, resource, list };
  },
};

export const resources = {
  namespaced: true,
  state,
  mutations,
  actions,
};
