export const Is = [
  {
    label: "是",
    value: true,
  },
  {
    label: "否",
    value: false,
  },
];
