<template>
  <div class="c-content">
    <ul class="c-content__toolbar">
      <li title="富文本" @click="richText.show()">
        <i class="c-iconfont c-iconfont--rich-text-filled"></i>
      </li>
      <li title="试题" @click="exam.show()">
        <i class="c-iconfont c-iconfont--work-filled"></i>
      </li>
      <li title="课件" @click="courseware.show()">
        <i class="c-iconfont c-iconfont--ppt"></i>
      </li>
    </ul>
    <el-scrollbar class="s-content" ref="scrollbar" height="750px">
      <draggable v-model="content" @start="onDragStart" @end="onDragEnd">
        <template #item="{ element: item, index }">
          <div>
            <el-popover
              :key="item.createdAt"
              popper-class="c-content-menu"
              placement="right-start"
              :width="90"
              trigger="hover"
              :disabled="dragging"
            >
              <ul>
                <li @click="insert('RichText', index)">插入富文本</li>
                <li @click="insert('Exam', index)">插入试题</li>
                <li @click="insert('Courseware', index)">插入课件</li>
                <li @click="update(item, index)">编辑</li>
                <li class="t-danger" @click="remove(index)">删除</li>
              </ul>
              <template #reference>
                <cc-item :item="item" :show-area="showArea" />
              </template>
            </el-popover>
          </div>
        </template>
      </draggable>
    </el-scrollbar>
  </div>
  <cc-rich-text ref="richText" @cancel="onCancel" @ok="onOk" />
  <cc-exam ref="exam" @cancel="onCancel" @ok="onOk" />
  <cc-courseware ref="courseware" @cancel="onCancel" @ok="onOk" />
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
