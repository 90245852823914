<template>
  <div class="c-main-container u-pt20">
    <div class="c-container">
      <slot name="breadcrumb" />
    </div>
    <div class="c-main-container__body c-scrollbar">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
