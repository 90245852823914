<template>
  <el-form
    class="b-login"
    ref="formRef"
    :model="cForm.model"
    :rules="cForm.rules"
  >
    <el-form-item prop="account">
      <el-input
        size="large"
        v-model="cForm.model.account"
        @keyup.enter="submit"
      >
        <template #prefix>
          <i class="c-iconfont c-iconfont--user-filled fs16"></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        type="password"
        size="large"
        autocomplete="new-password"
        v-model="cForm.model.password"
        @keyup.enter="submit"
      >
        <template #prefix>
          <i class="c-iconfont c-iconfont--lock-filled fs16"></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-row :gutter="10">
        <el-col :span="14">
          <el-input
            size="large"
            v-model="cForm.model.captcha"
            @keyup.enter="submit"
          >
            <template #prefix>
              <i class="c-iconfont c-iconfont--safe-filled fs16"></i>
            </template>
          </el-input>
        </el-col>
        <el-col :span="10">
          <div
            class="b-captcha"
            v-html="$helpers.formatHtml(cCaptcha.data)"
            @click="renderCaptcha"
          ></div>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        size="large"
        style="width: 100%"
        @click="submit"
      >
        登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
