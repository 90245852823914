<template>
  <el-dialog
    title="课件"
    v-model="cDialog.visible"
    width="350px"
    @closed="$emit('cancel')"
  >
    <el-form
      ref="formRef"
      class="cc-courseware c-scrollbar"
      :model="cForm.model"
      :rules="cForm.rules"
      label-position="right"
      label-width="90"
    >
      <el-form-item label="课件文件" prop="value">
        <c-upload
          :key="`${cForm.id}:value`"
          :cos-config="{
            cosApi: aliCloudOssApi,
            filesApi,
            uploadTo: UploadTo.AliCloudOss,
            region: 'oss-cn-hangzhou',
            bucket: 'fzznx-faie',
          }"
          v-model:value="cForm.model.value"
          @change="validateField('value')"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="cDialog.visible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
