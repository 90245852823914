import { keyMirror } from "jt-helpers";

const types = keyMirror({
  Initialize: null,
  Add: null,
  Update: null,
  Del: null,
  Clear: null,
});

const state = {
  dataList: [],
};

const mutations = {
  [types.Initialize](state, { dataList }) {
    state.dataList = dataList;
  },
  [types.Add](state, { data }) {
    state.dataList.push(data);
  },
  [types.Del](state, { index }) {
    state.dataList.splice(index, 1);
  },
  [types.Update](state, { index, data }) {
    state.dataList[index] = data;
  },
  [types.Clear](state) {
    state.dataList = [];
  },
};

const actions = {
  initialize({ commit }, { dataList }) {
    commit(types.Initialize, { dataList });
  },
  add({ commit }, { data }) {
    commit(types.Add, { data });
  },
  del({ commit }, { index }) {
    commit(types.Del, { index });
  },
  update({ commit }, { index, data }) {
    commit(types.Update, { index, data });
  },
  clear({ commit }) {
    commit(types.Clear);
  },
};

export const experimentSteps = {
  namespaced: true,
  state,
  mutations,
  actions,
};
