import { onMounted, ref } from "vue";
import { filesApi } from "@/apis/client/files";
import { useConsts } from "@/composables/use-consts";
import { useAuth } from "@/composables/use-auth";
import axios from "axios";

export default {
  props: {
    fileId: Number,
    synId: String,
  },
  setup(props) {
    const { CdnUrl } = useConsts();

    const { isTeacher } = useAuth();

    const pptRef = ref(null);

    const src = ref("");

    onMounted(async () => {
      const { date, uuid, ext } = await filesApi.get({ id: props.fileId });
      const url = `${CdnUrl}/${date}/${uuid}.${ext}`;

      if (isTeacher.value) {
        const {
          data: { data },
        } = await axios.get("https://static.faie.cn/test.php");
        src.value = `https://ow365.cn/?i=28208&ssl=1&n=5&syn=${props.synId}&synctl=${data}&furl=${url}`;
      } else {
        src.value = `https://ow365.cn/?i=28208&ssl=1&n=5&syn=${props.synId}&furl=${url}`;
      }
    });

    const gotoPage = (pageIndex) => {
      pptRef.value.contentWindow.postMessage(`goPage,${pageIndex - 1}`, "*");
    };

    const nextAnimate = () => {
      pptRef.value.contentWindow.postMessage("nextAnim", "*");
    };

    const prevAnimate = () => {
      pptRef.value.contentWindow.postMessage("preAnim", "*");
    };

    return {
      pptRef,
      src,
      gotoPage,
      nextAnimate,
      prevAnimate,
    };
  },
};
