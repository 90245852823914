<template>
  <el-upload
    :action="`${$consts.ApiUrl}/client/experiments/actions/upload`"
    :headers="$auth.getHeaders()"
    :multiple="multiple"
    :show-file-list="false"
    :on-success="onSuccess"
  >
    <div class="c-uploader__button">
      <i class="c-iconfont c-iconfont--upload-filled"></i>
      <p>上传图片</p>
    </div>
  </el-upload>
</template>

<script src="./script.js"></script>

<style lang="scss" src="./style.scss"></style>
