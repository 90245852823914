export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const onSuccess = (res) => {
      context.emit("ok", res.data);
    };

    return {
      onSuccess,
    };
  },
};
