import { RouterView } from "vue-router";
import { coursesRoute } from "./courses";
import { examsRoute } from "./exams";
import { experimentsRoute } from "./experiments";

export const resourcesRoute = {
  path: "resources",
  component: <RouterView />,
  children: [coursesRoute, examsRoute, experimentsRoute],
};
